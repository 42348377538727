import { InMemoryCache, ApolloLink, split } from '@apollo/client/core'
import { HttpLink } from 'apollo-angular/http'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '../environments/environment';
import { LocalizationService } from '@gcommerce/store/data-access';
import { HttpHeaders } from '@angular/common/http';

const uri = environment.graphQL
const wsUri = environment.ws

const headers = new HttpHeaders()
headers.append('Accept-Encoding', 'gzip')
headers.append('Content-Encoding', 'gzip')

export function createApollo(httpLink: HttpLink, localizationService: LocalizationService) {
  const basic = setContext(() => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }))

  const auth = setContext(() => {
    return {
      headers: {
        apiKey: '1ab2c3d4e5f61ab2c3d4e5f6',
        client: 'store',
        authToken: localStorage.getItem('token') || '',
        local: localizationService.locale
      },
    }
  })

  const error = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      if (!environment.production || environment.production) console.log(graphQLErrors)

    if (graphQLErrors)
      if (!environment.production || environment.production) graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) {
      if (!environment.production) console.log(networkError)
    }
  })

  const http = httpLink.create({
    uri,
    headers: headers
  });

  const ws = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true
    }
  });

  const link = ApolloLink.from([
    error,
    basic,
    auth,
    split(
      ({ query }) => {
        let definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      ws,
      http,
    )
  ])

  // const link: ApolloLink = ;

  const cache = new InMemoryCache({
    typePolicies: {
      Muatation: {
        fields: {
          createOrder: {
            merge: true
          }
        }
      },
      Query: {
        fields: {
          cartItem: {
            merge: true
          },
          cart: {
            merge: true
          }
        }
      }
    }
  })

  const defaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  }

  return {
    link,
    cache,
    defaultOptions
  }
}

