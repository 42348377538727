import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from './../../../../../../apps/store/src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  map!: mapboxgl.Map;
  navMap!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 40.22490;
  lng = 44.57574;
  zoom = 16

  constructor() {}

  buildMap() {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat]
    })
  }

  buildNavMap() {
    this.map = new mapboxgl.Map({
      container: 'navMap',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat]
    })

    this.map.addControl(new mapboxgl.NavigationControl());
  }

}
