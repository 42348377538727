import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { LocalizationService } from '@gcommerce/store/data-access';

import { AppComponent } from './app.component';
import { createApollo } from './create-apollo';
import { environment } from '../environments/environment';

const locale = localStorage.getItem('activeLocale') || 'hy '

const routes: Routes = [
  { path: 'hy', loadChildren: () => import('@gcommerce/store/shell').then(m => m.StoreShellModule) },
  { path: 'en', loadChildren: () => import('@gcommerce/store/shell').then(m => m.StoreShellModule) },
  { path: 'ru', loadChildren: () => import('@gcommerce/store/shell').then(m => m.StoreShellModule) },
  { path: '', pathMatch: 'full', redirectTo: '/hy' },
  { path: 'order/:status', redirectTo: locale + '/order/:status' },
  // { path: '**', redirectTo: '/hy' }
]

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, LocalizationService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
