import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from '@gcommerce/store/pages/auth';
import { AddToCartGQL, CartDocument, ClearCartGQL, CreateCartGQL, DeleteFromCartGQL } from '@gcommerce/store/data-access';

import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  cartSub = new Subject<any>();
  animate = new Subject<boolean>();
  translations: any

  constructor(
    private _snackBar: MatSnackBar,
    private readonly translationService: TranslationService,
    private readonly authService: AuthService,
    private readonly addToCartGql: AddToCartGQL,
    private readonly createCartGql: CreateCartGQL,
    private readonly deleteFromCartGql: DeleteFromCartGQL,
    private readonly clearCartGql: ClearCartGQL,
    private spinner: NgxSpinnerService
  ) {
    this.translations = this.translationService.translations
  }

  addToCart(product: any, quantity: number) {
    if (!this.authService.currentUser) {
      this.addLocal(product, quantity);
      return
    }

    this.add(product.id, quantity, product.title)
  }

  removeFromCart(product: any) {
    if(!this.authService.currentUser) {
      this.removeLocal(product)
      return
    }

    this.remove(product.id)
  }

  claerCart() {
    if(!this.authService.currentUser) {
      this.clearLocal();
      return
    }

    this.clear()
  }

  createCart() {
    const cart = JSON.parse(localStorage.getItem('cart') || '{"total":0,"count":0,"items":[]}');
    const items = []

    for (let item of cart.items) {
      items.push({ productId: item.product.id, quantity: item.quantity })
    }

    this.createCartGql.mutate({input: items}, {
      update: (store) => {
        const data: any = store.readQuery({ query: CartDocument });
        store.writeQuery({ query: CartDocument, data });
      }
    }).subscribe(() => {
      localStorage.removeItem('cart')
    })
  }

  // Add to cart server
  private add(productId: number, quantity: number, productName: string) {
    this.spinner.show()

    this.addToCartGql.mutate({productId, quantity}, {
      update: (store) => {
        const data: any = store.readQuery({ query: CartDocument });
        store.writeQuery({ query: CartDocument, data });
      }
    }).subscribe(res => {
      if (res.data) {
        this.spinner.hide()
        this.showSnackbar(productName)
      }
    }, () => {
      this.spinner.hide();
    })
  }

  // Remove item from cart by product id
  private remove(productId: number) {
    this.spinner.show()

    this.deleteFromCartGql.mutate({productId}, {
      update: (store) => {
        const data: any = store.readQuery({ query: CartDocument });
        store.writeQuery({ query: CartDocument, data });
      }
    }).subscribe(res => {
      if (res.data) {
        this.spinner.hide()
      }
    }, () => {
      this.spinner.hide();
    })
  }

  // Clear cart aka delete all products in cart
  private clear() {
    this.spinner.show()

    this.clearCartGql.mutate({}, {
      update: (store) => {
        const data: any = store.readQuery({ query: CartDocument });
        store.writeQuery({ query: CartDocument, data });
      }
    }).subscribe(res => {
      if (res.data) {
        this.spinner.hide()
      }
    }, () => {
      this.spinner.hide();
    })
  }

  // Clear Cart Local
  private clearLocal() {
    const cart = { total: 0, count: 0, items: [] }
    localStorage.setItem('cart', JSON.stringify(cart));
    this.cartSub.next(cart);
  }

  // Remove cart item from local storage
  private removeLocal(product: any) {
    const cart = JSON.parse(localStorage.getItem('cart') || '{"total":0,"count":0,"items":[]}');
    const exists = cart.items.find((item: any) => item.product.id === product.id);

    cart.total -= exists.quantity * exists.product.price;
    cart.count -= exists.quantity;
    cart.items = cart.items.filter((item: any) => item.product.id !== product.id)

    localStorage.setItem('cart', JSON.stringify(cart));
    this.cartSub.next(cart);
  }

  // Add cart item to local storage
  private addLocal(product: any, quantity: number) {
    const cart = JSON.parse(localStorage.getItem('cart') || '{"total":0,"count":0,"items":[]}');
    const exists = cart.items.find((item: any) => item.product.id === product.id);

    if (cart.count + quantity <= 0) {
      return
    }

    cart.total += product.price * quantity;
    cart.count += quantity;

    if (exists) {
      exists.quantity += quantity;
    } else {
      cart.items.push({ quantity: quantity, product: product });
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    this.cartSub.next(cart);
  }

  // Show Message
  showSnackbar(name: string) {
    const message: string = `${name} ${this.translations['productAddedToCart']}`

    this._snackBar.open(message, 'OK', {
      duration: 5000
    });
  }

}
