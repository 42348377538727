<section class="store-auth-page">

  <div>
    <ng-container *ngIf="!isProfile">
      <gcommerce-login></gcommerce-login>
    </ng-container>

    <ng-container *ngIf="isProfile">
      <gcommerce-registration></gcommerce-registration>
    </ng-container>
  </div>

</section>
