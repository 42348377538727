import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormArray, FormGroup } from '@angular/forms';
import { GraphQLError } from 'graphql';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountryISO } from 'ngx-intl-tel-input';
import * as moment from 'moment';

import { CreateProfileGQL, CurrentUserDocument, CurrentUserGQL, TranslationService, UpdateUserGQL } from '@gcommerce/store/data-access';

import { FormErrorStateMatcher } from './../form-error';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'gcommerce-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass'],
})
export class RegistrationComponent implements OnInit {
  matcher = new FormErrorStateMatcher();
  translations: any;
  user: any;

  profileForm = this.fb.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    birthdate: this.fb.group({
      day: '',
      month: '',
      year: '',
    }),
    phone: [''],
    email: [''],
    shipping: this.fb.array([
      this.fb.group({
        address: [''],
      }),
    ]),
  });

  data: any;
  countryISO = CountryISO;
  showPhoneError: boolean = false;
  minDate: Date = new Date(1,1,1900)
  maxDate: Date = new Date()

  months: any= []
  years: any = []

  constructor(
    private _snackBar: MatSnackBar,
    private readonly router: Router,
    private dialogRef: MatDialogRef<RegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly translationService: TranslationService,
    private readonly fb: FormBuilder,
    private readonly createProfileGql: CreateProfileGQL,
    private readonly currentUserGQL: CurrentUserGQL,
    private readonly updateUserGQL: UpdateUserGQL
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.translations = this.translationService.translations;

    this.currentUserGQL.watch().valueChanges.subscribe((res) => {
      if (res.data) {
        this.user = res.data.currentUser;
        this.phoneEmailRequired(res.data.currentUser)
      }
    });

    this.initMnthYr()

    this.birthdateGroup.valueChanges.subscribe()
  }

  get firstname() {
    return this.profileForm.get('firstname') as FormControl;
  }

  get lastname() {
    return this.profileForm.get('lastname') as FormControl;
  }

  get birthdateGroup() {
    return this.profileForm.get('birthdate') as FormGroup;
  }

  get birthdate() {
    if (this.birthdateGroup.get('day')?.value === '' || this.birthdateGroup.get('month')?.value === '' || this.birthdateGroup.get('year')?.value === '') {
      return null
    }

    const birthdate = new Date(this.birthdateGroup.get('year')?.value, this.birthdateGroup.get('month')?.value, this.birthdateGroup.get('day')?.value)

    return birthdate ? birthdate : null
  }

  get phone() {
    return this.profileForm.get('phone') as FormControl;
  }

  get email() {
    return this.profileForm.get('email') as FormControl;
  }

  get shipping() {
    return this.profileForm.get('shipping') as FormArray;
  }

  addAddress() {
    const shippingForm = this.fb.group({
      address: [''],
    });

    this.shipping.push(shippingForm);
  }

  createProfile() {
    this.profileForm.markAllAsTouched()

    if (this.profileForm.invalid) {
      if (this.phone.invalid) {
        this.showPhoneError = true
      }

      return;
    }

    const input: any = {
      uid: this.user.id,
      input: {
        firstname: this.firstname.value,
        lastname: this.lastname.value,
        birthdate: this.birthdate,
      },
      address: this.shipping.value,
    };

    if (!this.user.email) {
      input.input.email = this.email.value;
    }

    if (!this.user.phone) {
      input.input.phone = this.phone.value.e164Number;
    }

    this.createProfileGql.mutate(input).subscribe(res => {
      if (res.data?.createProfile.id) {
        this.updateUser();
      }
    }, (error: GraphQLError) => {
      this.dialogRef.close();
      this.showErrorMsg(error.message)
    });
  }

  updateUser() {
    const input: any = {};

    if (!this.user.email) {
      input.email = this.email.value;
    }

    if (!this.user.phone) {
      input.phone = this.phone.value.e164Number;
    }

    this.updateUserGQL.mutate({ uid: this.user.id, input }, {
      update: (store, result) => {
        store.writeQuery({
          query: CurrentUserDocument,
          data: { currentUser: result.data?.updateUser },
        });
      },
    }).subscribe((res) => {
      if (res.data) {
        this.dialogRef.close();
        this.router.navigateByUrl(this.data.redirect);
      }
    });
  }

  phoneEmailRequired(user: any) {
    if (!user.email) {
      this.email.setValidators(Validators.required)
    }

    if (!user.phone) {
      this.phone.setValidators(Validators.required)
    }
  }

  showErrorMsg(msg: string) {
    if (msg === 'duplicate phone') {
      this._snackBar.open(this.translations['duplicatePhoneError'], 'OK', {
        duration: 5000
      });
    }

    if (msg === 'duplicate email') {
      this._snackBar.open(this.translations['duplicateEmailError'], 'OK', {
        duration: 5000
      });
    }

    this._snackBar.open(this.translations['registrationError'], 'OK', {
      duration: 5000
    });
  }

  initMnthYr() {
    const locale = localStorage.getItem('locale') || 'en'
    moment.locale(locale)

    const currentYear = new Date().getFullYear()

    this.months = moment.months()

    for (let i = currentYear; i > currentYear - 100; i--) {
      this.years.push(i)
    }
  }
}
