import { LocalizationService } from './localization.service';
import { Injectable } from '@angular/core';

import { AllTranslationsGQL } from '../generated/generated';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  translations: any = {}

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly allTranslationsGql: AllTranslationsGQL
  ) {
    this.curretnLocalData(this.localizationService.locale)
  }

  switchLanguage(code: string) {
    localStorage.setItem('local', code)
    this.curretnLocalData(code)
    this.setLocale(code)
    window.location.reload()
  }

  setLocale(code: string) {
    switch (code) {
      case 'hy':
        localStorage.setItem('locale', 'hy-AM')
        break;
      case 'en':
        localStorage.setItem('locale', 'en-US')
        break;
      case 'ru':
        localStorage.setItem('locale', 'ru-RU')
        break;
      default:
        localStorage.setItem('locale', 'en-US')
        break;
    }
  }

  private curretnLocalData(code: string) {
    this.allTranslationsGql.watch().valueChanges.subscribe(res => {
      for (let translation of res.data.translations) {
        const current = translation.items.find((item: any) => item.local === code)
        this.translations[translation.name] = current?.value
      }
    })
  }
}
