import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuard  {
  constructor(private readonly service: AuthService, public dialog: MatDialog) {}

  canActivate(): boolean | Promise<boolean> {
    return this.service.checkUser()
  }
}
