import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CurrentUserGQL, LogoutGQL, LocalizationService } from '@gcommerce/store/data-access';

import { AuthPageComponent } from './auth-page/auth-page.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: any;
  token!: string;

  constructor(
    private readonly localizationService: LocalizationService,
    public dialog: MatDialog,
    private readonly currentUserGQL: CurrentUserGQL,
    private readonly logoutGQL: LogoutGQL,
  ) {
    this.currentUserGQL.watch().valueChanges.subscribe(res => {
      if (res.data.currentUser) {
        this.currentUser = res.data.currentUser

        if (!res.data.currentUser?.profile) {
          const url = window.location.pathname
          this.loginForm(url, true)
        }
      }
    })
  }

  get isLogedIn(): boolean {
    return this.currentUser ? true : false;
  }

  async getAuthorized(): Promise<boolean> {
    const res = await this.currentUserGQL.watch().result()
    return res?.data.currentUser ? true : false
  }

  loginForm(redirect?: string, isProfile?: boolean, showGuest?: boolean, guestUrl?: string) {
    this.dialog.open(AuthPageComponent,  {
      maxWidth: '1170px',
      width: 'fit-content',
      maxHeight: '90vh',
      panelClass: ['container'],
      disableClose: isProfile,
      data: {
        redirect: redirect,
        isProfile: isProfile,
        showGuest: showGuest,
        guestUrl: guestUrl
      }
    })
  }

  async checkUser(redirect?: string, showGuest?: boolean, guestUrl?: string) {
    const isUser = await this.getAuthorized()

    if (!isUser) {
      this.loginForm(redirect, false, showGuest, guestUrl)
    }

    return isUser
  }

  logout() {
    this.logoutGQL.mutate({}, {
      update: (store) => {
        store.reset()
      }
    }).subscribe(res => {
      if (res.data?.logout) {
        this._removeUserData()
        window.location.href = this.localizationService.locale
      }
    })
  }

  private _removeUserData() {
    localStorage.removeItem('uid')
    localStorage.removeItem('token')
  }
}
