import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslationService } from '@gcommerce/store/data-access';

@Component({
  selector: 'gcommerce-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.sass'],
})
export class AuthPageComponent implements OnInit {
  translations: any;
  isProfile!: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly translationService: TranslationService
  ) {
    this.isProfile = data.isProfile
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.translations = this.translationService.translations;
    }, 500);
  }
}
