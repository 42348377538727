import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationOptions } from 'intl-input-phone';
import { CountryISO } from 'ngx-intl-tel-input';

import { CartService, CurrentUserDocument, LoginGQL, PasswordlessGQL, TranslationService } from '@gcommerce/store/data-access';

import { RegistrationComponent } from './../registration/registration.component';
import { FormErrorStateMatcher } from '../form-error';
import { PolicyComponent } from '../policy/policy.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'gcommerce-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  matcher = new FormErrorStateMatcher();
  configOptions!: ConfigurationOptions;

  currentUser: any;
  translations: any;
  confirmationResult!: Promise<any>;

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    phone: ['', Validators.required],
  });

  terms = this.fb.control([true, Validators.required]);

  isEmailLogin: boolean = false;
  isEmailSend: boolean = false;
  isSmsSend: boolean = false;
  isCodeSend: boolean = false;

  redirectUrl: any;
  countryISO = CountryISO;
  showPhoneError: boolean = false;

  showGuest: boolean = false
  guestUrl!: string

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly translationService: TranslationService,
    private readonly fb: FormBuilder,
    private readonly passwordlessGQL: PasswordlessGQL,
    private readonly loginGql: LoginGQL,
    private readonly cartService: CartService,
    private spinner: NgxSpinnerService
  ) {
    this.redirectUrl = data.redirect;
    this.showGuest = data.showGuest
    this.guestUrl = data.guestUrl
  }

  ngOnInit(): void {
    this.configOptions = new ConfigurationOptions();
    this.spinner.hide();

    this.translations = this.translationService.translations;
  }

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get password() {
    return this.loginForm.get('password') as FormControl;
  }

  get phone() {
    return this.loginForm.get('phone') as FormControl;
  }

  togleAuthMode() {
    this.isEmailLogin = !this.isEmailLogin;
  }

  sendEmail() {
    if (this.email.invalid) {
      return;
    }

    this.isEmailSend = true

    this.passwordlessGQL.fetch({ email: this.email.value }).subscribe();
  }

  sendSMS() {
    this.showPhoneError = true;

    if (this.phone.invalid) {
      return;
    }

    this.isSmsSend = true

    this.passwordlessGQL.fetch({ phone: this.phone.value.e164Number }).subscribe();
  }

  login() {
    if (this.password.invalid || !this.terms.value) {
      return;
    }

    const payload = {
      email: this.email.value,
      phone: this.phone.value?.e164Number,
      password: this.password.value,
    };

    this.loginGql.mutate(payload, {
      update: (store, result) => {
        store.writeQuery({
          query: CurrentUserDocument,
          data: { currentUser: result.data?.login.user },
        });
      },
    }).subscribe((res) => {
      if (res) {
        this._processUser(res.data?.login);
      }
    });
  }

  registrationForm(uid: number) {
    this.dialog.closeAll();
    this.dialog.open(RegistrationComponent, {
      maxHeight: '90vh',
      data: {
        uid: uid,
        redirect: this.redirectUrl,
      },
      disableClose: true
    });
  }

  _processUser(payload: any) {
    localStorage.setItem('uid', payload.user.id.toString());
    localStorage.setItem('token', payload.token);

    if (!payload.user.cart) {
      this.cartService.createCart();
    }

    if (payload.user.profile) {
      this.dialogRef.close();
      window.location.href = this.redirectUrl ? this.redirectUrl : ''
    } else {
      this.registrationForm(payload.user.id);
    }
  }

  showPolicy(event: any) {
    event.preventDefault();
    const dialogRef = this.dialog.open(PolicyComponent, {
      maxWidth: '100%',
      panelClass: [
        'container',
        'animate__animated',
        'animate__faster',
        'animate__zoomIn',
      ],
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.terms.setValue(res);
    });
  }

  onCodeCompleted(event: any) {
    this.password.setValue(event)
    this.login()
  }

  continueAsGuest() {
    window.location.href = this.guestUrl
  }
}
