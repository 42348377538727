import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private titleService: Title, private metaService: Meta) {}

  update(title: string, description: string, image?: any) {
    const logo = 'https://ik.imagekit.io/gv1iemhs2/store/logo_JqwvgEb4g.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651062501597'

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', description })
    this.metaService.updateTag({ name: 'robots', content: 'index,follow' });
    this.metaService.updateTag({ property: 'og:url', content: location.href });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: image || logo });
  }

}
