<section class="store-create-profile-page">
  <h1 class="title">{{translations.profileCreateTitle}}</h1>

  <form [formGroup]="profileForm" class="profile-form">
    <div class="form-body">
      <mat-form-field appearance="outline">
        <mat-label>{{translations.firstname}}</mat-label>
        <input matInput formControlName="firstname" [errorStateMatcher]="matcher" required>
        <mat-error *ngIf="firstname.invalid">{{translations.firstnameError}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{translations.lastname}}</mat-label>
        <input matInput formControlName="lastname" [errorStateMatcher]="matcher" required>
        <mat-error *ngIf="lastname.invalid">{{translations.lastnameError}}</mat-error>
      </mat-form-field>

      <div class="birthdate" [formGroup]="birthdateGroup">
        <mat-form-field appearance="outline" class="day">
          <mat-label>{{translations.day}}</mat-label>
          <input matInput formControlName="day" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="month">
          <mat-label>{{translations.month}}</mat-label>
          <mat-select formControlName="month" >
            <mat-option [value]="i" *ngFor="let item of months, let i = index">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="year">
          <mat-label>{{translations.year}}</mat-label>
          <mat-select formControlName="year" >
            <mat-option [value]="item" *ngFor="let item of years">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <ng-container *ngIf="user">
        <mat-form-field appearance="outline" *ngIf="!user.email">
          <mat-label>{{translations.email}}</mat-label>
          <input matInput placeholder="example@domain.com" formControlName="email" [errorStateMatcher]="matcher">
          <mat-error *ngIf="email.invalid">{{translations.emailError}}</mat-error>
        </mat-form-field>

        <div class="form-input" *ngIf="!user.phone">
          <ngx-intl-tel-input
            [cssClass]="showPhoneError ? 'phone-input error' : 'phone-input'"
            [onlyCountries]="[countryISO.Armenia]"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="false"
            [searchCountryFlag]="false"
            [selectFirstCountry]="false"
            [selectedCountryISO]="countryISO.Armenia"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="true"
            name="phone"
            formControlName="phone"
          ></ngx-intl-tel-input>
          <mat-error class="error" *ngIf="showPhoneError && phone.invalid">{{translations.phoneError}}</mat-error>
        </div>

      </ng-container>

      <div class="address-container" formArrayName="shipping">
        <ng-container *ngFor="let item of shipping.controls; let i=index">
          <div class="group" [formGroupName]="i">
            <mat-form-field appearance="outline">
              <mat-label>{{translations.address}}</mat-label>
              <input matInput id="address-{{i}}" formControlName="address">
            </mat-form-field>
          </div>
        </ng-container>

        <button class="add-address-btn" (click)="addAddress()">
          <span class="icon">+</span>
          <span class="btn-title">{{translations.addNewAddress}}</span>
        </button>
      </div>
    </div>

    <div class="submit-btn-container">
      <button (click)="createProfile()" class="submit-btn">
        <span>{{translations.continue | titlecase}}</span>
      </button>
    </div>

  </form>

</section>
