import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";

import { AddToWidhListGQL, UserWishListDocument, UserWishListGQL } from '../generated/generated';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  animate = new Subject<boolean>()
  wishlist: any

  constructor(
    private readonly userWishlistGQL: UserWishListGQL,
    private readonly addToWishListGql: AddToWidhListGQL
  ) {
    this.userWishlistGQL.watch().valueChanges.subscribe(res => {
      if (res.data) {
        this.wishlist = res.data.userWhishList
      }
    })
  }

  add(productId: number) {
    this.addToWishListGql.mutate({ productId }, {
      update: (store, result) => {
        const data: any = store.readQuery({ query: UserWishListDocument });
        store.writeQuery({
          query: UserWishListDocument,
          data: {
            userWhishList: [
              ...data.userWhishList,
              result.data?.addToWishList,
            ],
          },
        });
      },
    }).subscribe();
  }

  inWishlist(pid: number): any {
    if (!this.wishlist) return

    const exists = this.wishlist.find((item: any) => item.product.id === pid);

    if (!exists) {
      return false
    }

    return true
  }


}
