import { Injectable } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  locales = ['hy', 'en', 'ru']
  defaultLocal = 'hy'

  constructor(private router: Router) {
    router.events.subscribe((event: any): void => {
      if (event instanceof NavigationEnd) {
        document.querySelector('html')?.setAttribute('lang', this.locale)
      }
    });
  }

  public get locale(): string {
    const lang = !this.router.url.includes('#prod') ?
      this.router.url.split('/')[1] :
      this.router.url.split('#')[0].replace('/', '')

    const isLang = this.locales.includes(lang)

    return isLang ? lang : this.defaultLocal
  }

  switcher(locale: string) {
    const url = this.router.url.split('/')
    const isLang = this.locales.includes(locale)

    if (isLang) {
      url[1] = locale
    } else {
      url.splice(1,1)
    }

    switch (locale) {
      case 'hy':
        localStorage.setItem('locale', 'hy-AM')
        break;
      case 'en':
        localStorage.setItem('locale', 'en-US')
        break;
      case 'ru':
        localStorage.setItem('locale', 'ru-RU')
        break;
      default:
        localStorage.setItem('locale', 'hy-AM')
        break;
    }

    window.location.href = url.join('/')
  }
}
