import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CodeInputModule } from 'angular-code-input';

// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';

import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './guards/auth.guard';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { PolicyComponent } from './policy/policy.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: false,
      code: 'abcdef',
    }),
  ],
  declarations: [
    LoginComponent,
    RegistrationComponent,
    AuthPageComponent,
    PolicyComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AuthService,
    AuthGuard,
  ],
  exports: [AuthPageComponent],
})
export class StorePagesAuthModule {}
