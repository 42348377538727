import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { environment } from '../environments/environment';

declare const gtag: Function;

@Component({
  selector: 'gcommerce-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'Yeranee';
  local: string | null = localStorage.getItem('local');
  locale: string | null = localStorage.getItem('locale');
  loading!: boolean;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private swUpdate: SwUpdate,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.ga, { 'page_path': event.urlAfterRedirects });
      }
    })

    router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    if (!this.local) {
      localStorage.setItem('local', 'hy');
    }
    if (!this.locale) {
      localStorage.setItem('locale', 'hy-AM');
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        // window.location.reload();
      });
    }

    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(keyList.map(function (key) {
          return caches.delete(key);
        }));
      })
    }

  }

}
