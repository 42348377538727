<div class="site-policy">
  <h1 mat-dialog-title>ՊԱՅՄԱՆՆԵՐ ԵՎ ԴՐՈՒՅԹՆԵՐ</h1>
  <mat-dialog-content id="site-policy" class="mat-typography">
    <h3>Պայմանագրում օգտագործվող հասկացությունները</h3>
    <p>
      <strong>Գնորդ՝</strong> սույն օֆերտայի պայմաններով օֆերտան ընդունած ֆիզիկական (չափահաս) ցանկացած անձ:
    </p>
    <p>
      <strong>Վաճառող՝</strong> «Երանի Փրոդակտս» ՍՊ ընկերություն:
    </p>
    <p>
      <strong>Ապրանք՝</strong> օֆերտան ընդունած ֆիզիկական անձի կողմից
      <span>
        <a [routerLink]="['/', activeLocale]" class="url" target="_blank">www.yeranee.am</a>
      </span> կայքում վաճառվող/առաջարկվող ցանկացած ապրանք և/կամ ապրանքներ:
    </p>
    <p>
      <strong>Առաքիչ՝</strong> «Երանի Փրոդակտս» ՍՊ ընկերության կողմից լիազորված անձ, որն իրականացնում է պատվիրված Ապրանքի առաքումը Գնորդին վերջինիս կողմից նշված վայրում:
    </p>
    <p>
      <strong>Պատվեր՝ </strong>
      <span>
        <a [routerLink]="['/', activeLocale]" class="url" target="_blank">www.yeranee.am</a>
      </span> կայքում վաճառքի համար ներկայացված ցանկացած ապրանքի գնման դեպքում այն Գնորդին հասցնելու/առաքելու պահանջ:
    </p>

    <div class="points">
      <div class="point" *ngFor="let item of policy; let i = index">
        <h3 class="item-title">
          <strong class="order">{{i + 1}}</strong>{{item.title}}
        </h3>

        <div class="item-points" *ngIf="isString(item.points)">
          <p *ngFor="let point of item.points; let p = index">
            <strong class="order">{{i + 1}}.{{p + 1}}</strong>{{point}}
          </p>
        </div>

        <div class="item-points" *ngIf="!isString(item.points)">
          <div *ngFor="let point of item.points; let p = index">
            <h4 class="item-title">
              <strong class="order">{{i + 1}}.{{p + 1}}</strong>{{point.title}}
            </h4>
            <div class="">
              <p *ngFor="let subPoint of point.points; let s = index">
                <strong class="order">{{i + 1}}.{{p + 1}}.{{s + 1}}</strong>{{subPoint}}
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{translations.cancel}}</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{translations.agree}}</button>
  </mat-dialog-actions>
</div>

