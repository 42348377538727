<section class="store-login" *ngIf="translations">
  <div class="guest-container" *ngIf="showGuest">
    <button class="guest-btn" (click)="continueAsGuest()">{{translations.continuAsGuest}}</button>
  </div>

  <h1 class="title">{{translations.loginRegTitle}}</h1>

  <form [formGroup]="loginForm" class="login-form">
    <ngx-spinner bdColor="rgba(51,51,51,0.7)" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>

    <mat-form-field appearance="outline" *ngIf="isEmailLogin && !isEmailSend">
      <mat-label>{{translations.email}}</mat-label>
      <input matInput placeholder="example@domain.com" formControlName="email" [errorStateMatcher]="matcher" required>
      <mat-error *ngIf="email.invalid">{{translations.emailError}}</mat-error>
    </mat-form-field>

    <div class="form-input" *ngIf="!isEmailLogin && !isSmsSend">
      <ngx-intl-tel-input
        [cssClass]="'phone-input'"
        [onlyCountries]="[countryISO.Armenia]"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="false"
        [searchCountryFlag]="false"
        [selectFirstCountry]="false"
        [selectedCountryISO]="countryISO.Armenia"
        [maxLength]="15"
        [phoneValidation]="true"
        [separateDialCode]="true"
        name="phone"
        formControlName="phone"
      ></ngx-intl-tel-input>
      <mat-error class="error" *ngIf="showPhoneError && phone.invalid">{{translations.phoneError}}</mat-error>
    </div>

    <div class="code-field" *ngIf="isEmailSend || isSmsSend">
      <code-input [isCodeHidden]="false" [codeLength]="6" (codeCompleted)="onCodeCompleted($event)"></code-input>

      <p class="hint">{{translations.passwordSentFormMessage}}</p>
    </div>

    <button (click)="sendEmail()" class="submit-btn" *ngIf="isEmailLogin && !isEmailSend">
      <span>{{translations.continue | titlecase}}</span>
    </button>

    <button (click)="sendSMS()" class="submit-btn" *ngIf="!isEmailLogin && !isSmsSend">
      <span>{{translations.continue | titlecase}}</span>
    </button>

  </form>

  <!-- <div class="state-container">
    <button (click)="togleAuthMode()" class="togle-btn">
      <span *ngIf="isEmailLogin">{{translations.signInWithPhone}}</span>
      <span *ngIf="!isEmailLogin">{{translations.signInWithEmail}}</span>
    </button>
  </div> -->

  <div class="logo">

  </div>

  <div class="login-terms">
    <div class="input">
      <input type="checkbox" [formControl]="terms" id="login-terms">
    </div>
    <div [ngClass]="terms.value ? 'label' : 'label error' ">
      <label for="login-terms">
        <span>{{translations.signInTerms}}</span>
        <span>&nbsp;</span>
        <span class="terms-btn" (click)="showPolicy($event)">{{translations.websiteTerms}}</span>
      </label>
    </div>
  </div>

</section>
